import { getApi } from '@core/api'
import ErrorHandler from '@core/api/ErrorHandler'
import { addSnack } from '@core/snack/snack-state'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const saveSubmodels = createAsyncThunk<
  { selectedSubmodels: string[] },
  { selectedSubmodels: string[]; itemId: string }
>('metadata/saveSubmodels', async ({ selectedSubmodels, itemId }) => {
  const api = getApi()

  try {
    await api.updateContentVersions({
      throwOnError: true,
      path: { item_id: itemId },
      body: {
        items: [
          {
            itemId,
            submodels: selectedSubmodels,
          },
        ],
      },
    })
    return { selectedSubmodels }
  } catch (error) {
    console.error('error while saving submodels', error)

    addSnack({
      message: `Error overriding submodels: ${error.message}`,
      severity: 'error',
    })

    throw error
  }
})

export const saveMetadata = createAsyncThunk('metadata/saveMetadata', async (_, { getState }) => {
  const { externalMetadata, itemId } = getState().metadata
  const api = getApi()

  if (!itemId || !externalMetadata) {
    console.warn('saveMetadata - missing itemId', { itemId, externalMetadata })
    return
  }

  try {
    await api.updateContentVersions({
      throwOnError: true,
      path: { item_id: itemId },
      body: {
        items: [
          {
            itemId,
            externalMetadata,
          },
        ],
      },
    })
  } catch (error) {
    ErrorHandler(error)

    addSnack({
      message: `Error overriding submodels: ${error.message}`,
      severity: 'error',
    })

    throw error
  }
})
