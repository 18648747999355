/* eslint-disable no-alert */
import React, { Suspense, useEffect, useState } from 'react'
import { LinearProgress } from '@mui/material'
import { matchRoutes, Navigate, useLocation } from 'react-router-dom'

import { useApolloClient } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { useSubscription } from '@core/api'
import sentry from '@core/api/sentry'
import { routeMap } from '@core/constants/routes'
import env from '@core/env'
import { useMainState } from '@core/main-state'
import { addSnack } from '@core/snack/snack-state'
import { useAppDispatch, useAppSelector } from '@core/store'
import locationUtils from '@core/utils/location'

import UserError, { ErrorCode } from './main-errors'
import { PeriodicBackgroundTasks } from './main-idle-tasks'
import * as queries from './main-queries'
import { actions, RouteMatch } from './main-slice'
import type { Role, User } from './main-types'
import { claims, onChangePermissions, pendoSetup } from './main-utils'

export const Progress = () => (
  <LinearProgress data-testid="loader" aria-label="loader" color="secondary" />
)

declare global {
  interface Window {
    pendo: any
    Cypress: any
  }
}

type ProtectedRouteProps = {
  children: React.ReactNode
}

const routes = Object.values(routeMap).map((path) => ({ path }))

// A component that will watch for location changes and dispatch routeEnter and routeLeave actions
// It's not a hook, because then we can use it conditionally
const WatchLocation = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const result = matchRoutes(routes, location)

    if (!result?.length) return

    const { route, params } = result[0]

    const computedMatch: RouteMatch = { path: route.path, params, url: location.pathname }

    dispatch(actions.routeEnter(computedMatch))

    return () => {
      dispatch(actions.routeLeave(computedMatch))
    }
  }, [location.pathname])

  return null
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const client = useApolloClient()
  const [errorKey, setErrorKey] = useState<ErrorCode | string>('')
  const dispatch = useAppDispatch()
  const main = useAppSelector((state) => state.main)
  const { isAuthenticated, user, isLoading, getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(actions.reset())
    }
  }, [isAuthenticated])

  const setError = (code: ErrorCode) => {
    setErrorKey(code)
    if (user?.email) {
      dispatch(actions.set({ user: { email: user.email, role: 'User' } as User }))
    }
  }

  useEffect(() => {
    if (!isAuthenticated || !main.user?.id) return
    sentry.setUser({ id: main.user?.id })
  }, [isAuthenticated, main.user?.id])

  useSubscription(queries.GET_USER, {
    skip: isLoading || !isAuthenticated,
    async onData({ data }) {
      const userDB = data?.data?.user[0]
      const userAuth0 = user!

      // when the ftg_id inside the token does not match any record in our db
      if (!userDB) {
        setError('USER_NOT_FOUND_DB')
        return
      }

      const { customer, ...userData } = userDB

      if (!customer) {
        setError('USER_WITHOUT_CUSTOMER')

        return
      }

      const allowedRoles = user?.[claims]?.['x-hasura-allowed-roles'] || []

      if (!allowedRoles.length) {
        console.warn('User without allowed roles', user?.email, user?.[claims])
      }

      // we should consider the token as the truth
      const userRole: Role = allowedRoles.includes('customer_admin') ? 'Admin' : 'User'

      // role changed!
      if (userRole !== userDB.role) {
        let shouldReload = false

        await getAccessTokenSilently({ cacheMode: 'off' })

        // when lose the admin role
        if (userRole === 'Admin' && userDB.role === 'User') {
          window.alert('Your user role has been changed, reload the page')

          shouldReload = true
        }

        // when gain the admin role
        if (userRole === 'User' && userDB.role === 'Admin') {
          // check if the tab is active
          if (document.hidden) {
            shouldReload = true
            addSnack({
              message: 'Your user role has been changed, reloading the page',
              severity: 'info',
            })
          } else {
            shouldReload = window.confirm('Your user role has been changed, reload the page')
          }
        }

        if (shouldReload) {
          useMainState.getState().reset()
          dispatch(actions.reset())

          setImmediate(() => {
            locationUtils.reload()
          })

          return
        }
      }

      const internalAdmin = Boolean(
        userDB.role === 'Admin' && userDB.isInternal && allowedRoles.includes('internal_admin'),
      )

      const customerId = user?.[claims]?.['x-hasura-customer-id']

      // customer changed!
      if (customerId !== customer.id) {
        useMainState.getState().reset()
        dispatch(actions.reset())

        await getAccessTokenSilently({ cacheMode: 'off' }).catch(console.warn)

        // some time to clear the page before the redirect
        setTimeout(() => {
          onChangePermissions()
        }, 300)

        return
      }

      const mainState = {
        user: {
          ...userData,
          role: userRole,
          name: userAuth0.name || '',
          picture: userAuth0.picture || '',
          internalAdmin,
        },
        customer,
      }

      // keep doing both for a while
      useMainState.setState(mainState)
      dispatch(actions.set(mainState))
    },
    onError(error) {
      // the logout is already called in apollo "onErrorLink"
      if (/login required/i.test(error.message)) {
        return
      }

      console.error('queries.GET_USER subscription error', error)
      const isFirstTime = !main.user?.id

      if (isFirstTime) {
        setErrorKey(error?.message === 'undefined' ? 'FATAL_ERROR' : error?.message)
      }
    },
  })

  useEffect(() => {
    if (!user || !main.user?.id || errorKey) return

    const variables = {
      id: main.user.id,
      firstName: main.user.firstName || user.given_name || '',
      lastName: main.user.lastName || user.family_name || '',
      picture: user.picture || main.user.picture || '',
      lastAccess: new Date().toISOString(),
    }

    if (Boolean(env?.PENDO_ENABLED) && typeof window !== 'undefined' && !window.Cypress) {
      pendoSetup(main.user, main.customer)
    }

    client.mutate({ mutation: queries.UPDATE_USER, variables })
  }, [user?.name, main.user?.name, errorKey])

  if (!isLoading && !isAuthenticated) return <Navigate to={routeMap.LOGIN_ROUTE} />

  if (errorKey) {
    return <UserError code={errorKey} email={user?.email} />
  }

  if (isLoading || !main.user) {
    return <Progress />
  }

  return (
    <Suspense fallback={<Progress />}>
      {main.user?.id && <PeriodicBackgroundTasks userId={main.user.id} />}
      <WatchLocation />
      {props.children}
    </Suspense>
  )
}

export default ProtectedRoute
