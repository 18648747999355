export type ValueOf<T> = T[keyof T]

export const AnswerStatusMap = {
  key: 'CORRECT',
  distractor: 'DISTRACTOR',
  excluded: 'EXCLUDED',
} as const

export type AnswerStatus = ValueOf<typeof AnswerStatusMap> | null

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>
    }
  : T

export const ItemStatusMap = {
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE',
  FAILED: 'FAILED',
  NOT_STARTED: 'NOT_STARTED',
  POSSIBLE_KEYS: 'POSSIBLE_KEYS',
  RATIONALES: 'RATIONALES',
  PRETEST_BIAS: 'PRETEST_BIAS',
  PRETEST_KEY: 'PRETEST_KEY',
  GENERATE_MATH: 'GENERATE_MATH',
} as const

export const KeyCheckStatusMap = {
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE',
  FAILED: 'FAILED',
  EDITED: 'EDITED',
}

export type ItemStatus = ValueOf<typeof ItemStatusMap>
