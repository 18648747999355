export const getFilenameFromHeaders = (headers: Headers) => {
  const header = headers.get('Content-Disposition') || ''

  const filenameMatch = header.match(/filename\*=([^']*)'([^']*)'(.*)/)

  if (filenameMatch) {
    const encodedFileName = filenameMatch[3]

    // Decode the URL encoded filename
    const fileName = decodeURIComponent(encodedFileName)

    return fileName
  }

  // Fallback to regular filename parameter
  const regularMatch = header.match(/filename="(.*)"/)
  if (regularMatch) {
    return regularMatch[1]
  }

  return ''
}

/**
 * Downloads a file from a given data URL.
 *
 * @param {string} dataUrl - The data URL of the file to download.
 * @param {string} name - The name of the file to save as.
 * @param {Object} [options] - Additional options for downloading the file.
 * @param {boolean} [options.openInNewTab=false] -
 * Whether to open the file in a new browser tab instead of downloading it.
 *
 * @example
 * // File download
 * downloadFile('data:text/plain;base64,aGVsbG8gd29ybGQ=', 'image.jpg');
 *
 * @example
 * // File download in a new tab
 * downloadFile('data:text/plain;base64,aGVsbG8gd29ybGQ=', 'image.jpg', { openInNewTab: true });
 */

export const downloadFile = (
  dataUrl: string,
  name: string,
  options?: {
    openInNewTab?: boolean
  },
) => {
  try {
    const link = document.createElement('a')
    link.href = dataUrl

    link.download = name || 'download'

    if (options?.openInNewTab) {
      link.target = '_blank'
    }

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } catch (error) {
    console.error('Download failed:', error)
  }
}
