import React from 'react'
import fetch from 'cross-fetch'
import { createClient } from 'graphql-ws'

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  split,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { useAuth0 } from '@auth0/auth0-react'
import { onChangePermissions } from '@containers/main/main-utils'
import { build401ReturnTo } from '@core/api'
import sentry from '@core/api/sentry'
import env from '@core/env'
import { useMainState } from '@core/main-state'

import { useApiState } from './api-state'

type Headers = Record<string, string>

type Context = { role?: string; ws?: boolean }

const buildClient = ({ logout, getAccessTokenSilently }: ReturnType<typeof useAuth0>) => {
  const getToken = async (headers: Headers = {}, context: Context = {}) => {
    let token = ''

    try {
      token = await getAccessTokenSilently()
    } catch (error) {
      console.debug(`Error thrown calling getAccessTokenSilently ${{ error }}`, error)
      throw new Error('Login required')
    }
    // It's undocumented in the getAccessTokenSilently return type but it can return undefined if
    // the token is not found when cache mode is cache-only.
    if (!token) {
      console.debug('Token returned from getAccessTokenSilently was empty')
      throw new Error('Login required')
    }

    const role = context.role || headers['x-hasura-role'] || 'AUTHOR'

    return {
      headers: {
        ...headers,
        'x-hasura-role': role,
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
      ...context,
    }
  }

  const authMiddleware = setContext(async (_, { headers, ...context }) => {
    return getToken(headers, context)
  })

  const httpLink = createHttpLink({
    uri: ({ operationName }) => `${env.FTG_API_URL}?op=${operationName}`,
    fetch,
    credentials: 'same-origin',
  })

  const makeWSRoleLink = (role: string) => {
    return new GraphQLWsLink(
      createClient({
        url: env.FTG_WS_URL,
        connectionParams: () => getToken({ 'x-hasura-role': role }, { ws: true }),
        shouldRetry: () => !useMainState.getState().isIdle,
        lazy: true,
        disablePong: true,
      }),
    )
  }

  // as the headers are sent only on connection begins, is necessary to have one connection per role
  const wsLinks: ApolloLink = ApolloLink.split(
    (operation) => operation.getContext().role === 'internal_admin',
    makeWSRoleLink('internal_admin'),
    ApolloLink.split(
      (operation) => operation.getContext().role === 'customer_admin',
      makeWSRoleLink('customer_admin'),
      makeWSRoleLink('AUTHOR'),
    ),
  )

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      console.debug('starting request', query, definition)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLinks,
    httpLink,
  )

  const onErrorLink = onError((error) => {
    const { operation, networkError, graphQLErrors } = error
    const { source } = operation.query.loc || {}

    const messages = ['Item not found or without access', 'Without write access to the item']

    const errorMessage = graphQLErrors?.[0].message || networkError?.message

    if (errorMessage && messages.includes(errorMessage)) {
      // eslint-disable-next-line no-alert
      window.alert('You lost access to this item. Please refresh your browser to continue.')
      onChangePermissions()
      return
    }

    if (source && sentry.setContext) {
      sentry.setContext('source', { source })
    }

    const { response } = operation.getContext()

    if (response?.status === 401 || errorMessage === 'Login required') {
      // eslint-disable-next-line no-console
      console.debug(`401 or Login Required encountered: ${{ response, errorMessage }}`)
      sentry.setUser(null)

      logout({ logoutParams: { returnTo: build401ReturnTo() } })
      return
    }

    console.error(
      `onErrorLink - ${errorMessage || 'unknown'}`,
      JSON.stringify(
        {
          operation: { name: operation.operationName, variables: operation.variables },
          networkError,
          graphQLErrors,
          error, // Added this temporarily for verbose debugging of FTG-5598
        },
        null,
        2,
      ),
    )
  })

  return new ApolloClient({
    link: ApolloLink.from([onErrorLink, authMiddleware, splitLink].filter(Boolean)),
    cache: new InMemoryCache(),
  })
}

const AuthorizedApolloProvider = ({ children }: { children: React.ReactNode }) => {
  const client = useApiState((state) => state.client)
  const setClient = useApiState((state) => state.setClient)
  const auth0 = useAuth0()

  React.useEffect(() => {
    setClient(buildClient(auth0))
  }, [auth0.getAccessTokenSilently, auth0.logout])

  if (!client) {
    return null
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default AuthorizedApolloProvider
