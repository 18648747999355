import { gql, TypedDocumentNode } from '@apollo/client'

import type { RawItemSet } from './author-cloze-types'

export const GET_ITEM_SET: TypedDocumentNode<{ item: RawItemSet }, { id: string }> = gql`
  query fetchItemSet2($id: uuid!) {
    item: item_by_pk(id: $id) {
      id
      status
      type: content_type
      project {
        id
        name
        access {
          write
          download
        }
      }
      aiModel: ai_model {
        public_mapper
        flavors
        comments
        name
        qualityMetrics: quality_metrics
        type
        externalMetadataSettings: external_metadata_settings
      }
      job {
        id
        ownerId: owner_id
        selectedSubmodels: selected_submodels
      }
      currentContent: content_version(args: { content_type: "current" }) {
        submodels
        external_metadata
        content
        qualityMetrics: quality_metrics
        comments
      }
      savedContent: content_version(args: { content_type: "saved" }) {
        content
        submodels
        external_metadata
        comments
      }
      originalContent: content_version(args: { content_type: "original" }) {
        submodels
      }
      children {
        id
        type: content_type
        currentContent: content_version(args: { content_type: "current" }) {
          content
          submodels
        }
        savedContent: content_version(args: { content_type: "saved" }) {
          content
          submodels
        }
        originalContent: content_version(args: { content_type: "original" }) {
          submodels
        }
        status
        step
        contentFilter: content_filter
        aiModelId: ai_model_id
      }
    }
  }
`

export const GET_ITEM_CONTENT = gql`
  query fetchItemContent($id: uuid!) {
    item: item_by_pk(id: $id) {
      id
      currentContent: content_version(args: { content_type: "current" }) {
        content
        submodels
        qualityMetrics: quality_metrics
      }
      savedContent: content_version(args: { content_type: "saved" }) {
        content
      }
      originalContent: content_version(args: { content_type: "current" }) {
        submodels
      }
      status
      step
      type: content_type
      contentFilter: content_filter
    }
  }
`

export type ItemUpdatesChildData = {
  id: string
  status: string
  step: string
}

export type ItemUpdatesData = {
  id: string
  status: string
  step: string
  currentContent: {
    qualityMetrics: { value: number }[]
  }[]
  children: ItemUpdatesChildData[]
}

export const GET_ITEM_UPDATES: TypedDocumentNode<{ item: ItemUpdatesData }, { id: string }> = gql`
  subscription fetchItemSetStatus($id: uuid!) {
    item: item_by_pk(id: $id) {
      id
      status
      step
      currentContent: content_version(args: { content_type: "current" }) {
        qualityMetrics: quality_metrics
      }
      children {
        id
        status
        step
      }
    }
  }
`
