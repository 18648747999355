/* eslint-disable no-console */
import { generatePath } from 'react-router-dom'

import { actions as commentsActions } from '@containers/comments/comments-slice'
import { actions as metadataActions } from '@containers/metadata/metadata-slice'
import ErrorHandler from '@core/api/ErrorHandler'
import { ITEM_SET } from '@core/constants/content-type'
import { AUTHOR_ITEM_ROUTE } from '@core/constants/routes'
import { getUserRole } from '@core/main-state'
import { addSnack } from '@core/snack/snack-state'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { Item, ItemMap, ItemRoot, StateLoaded } from './author-cloze-types'
import * as utils from './author-cloze-utils'
import * as queries from './cloze-queries'

/**
 * Fetch initial item data
 */
export const fetchItemSet = createAsyncThunk<Partial<StateLoaded>, string>(
  'authorCloze/fetchItemSet',
  async (id, { extra, dispatch }) => {
    try {
      const { role } = getUserRole()

      const { data } = await extra.client.query({
        fetchPolicy: 'network-only',
        query: queries.GET_ITEM_SET,
        variables: { id },
        context: { role },
      })

      if (!data.item) {
        extra.navigate('/404')
        return { notFound: true }
      }

      const { children, root } = utils.normalizeItemSet(data.item)
      const items = { ...children, root } as ItemMap

      if (root.aiModel.type !== ITEM_SET) {
        const path = generatePath(AUTHOR_ITEM_ROUTE, { itemId: id })
        extra.navigate(path, { replace: true })
        return {}
      }

      dispatch(
        metadataActions.setInitialValues({
          itemId: root.id,
          originalSubmodels: root.originalContent.submodels,
          submodels: root.currentContent.submodels,
          isCloze: true,
          externalMetadata: root.currentContent.externalMetadata,
        }),
      )

      const { cloze_ids: clozeIds = [], mcq_ids: mcqIds = [] } = root.currentContent.content

      let startIndex = 1

      for (const itemId of [...mcqIds, ...clozeIds]) {
        const item = items[itemId]

        if (!item) {
          console.error('item not found', itemId)
          continue
        }

        items[itemId] = { ...item, index: startIndex }
        startIndex += 1
      }

      if (items) {
        dispatch(
          commentsActions.set({
            itemId: items.root.id,
            comments: items.root.currentContent.comments,
          }),
        )
      }

      return { initialized: true, items }
    } catch (error) {
      ErrorHandler(error)

      addSnack({
        message: `Error while fetching the item. ${error.message}`,
        severity: 'error',
      })

      throw error
    }
  },
)

/**
 * Refetch an item root content after some status change
 * If the response do not include the stimulus, wait some time and try again
 */
interface FetchItemContentParams {
  isRoot: boolean
  id: string
}

const wait = (t: number) => new Promise((r) => setTimeout(r, t))

async function tryFetchRoot(makeRequest, n = 1) {
  console.log('try fetch ->', n)
  if (n > 20) {
    throw new Error('Could not fetch root stimulus')
  }

  const { data } = await makeRequest()

  if (!data.item.currentContent[0].content.stimulus) {
    await wait(200 * n)
    return tryFetchRoot(makeRequest, n + 1)
  }
  const rootItem: ItemRoot = {
    ...data.item,
    currentContent: {
      content: data.item.currentContent?.[0]?.content,
      submodels: data.item.currentContent?.[0]?.submodels,
      qualityMetrics: data.item.currentContent?.[0]?.qualityMetrics,
    },
    savedContent: {
      content: data.item.savedContent?.[0]?.content,
    },
    originalContent: {
      submodels: data.item.originalContent?.[0]?.submodels,
    },
  }
  return rootItem
}

export const fetchItemContent = createAsyncThunk<Item, FetchItemContentParams>(
  'authorCloze/fetchItemContent',
  async ({ isRoot, id }, { extra }) => {
    const { role } = getUserRole()
    try {
      const makeRequest = () =>
        extra.client.query({
          fetchPolicy: 'network-only',
          query: queries.GET_ITEM_CONTENT,
          variables: { id },
          context: { role },
        })

      if (isRoot) return tryFetchRoot(makeRequest)

      const { data } = await makeRequest()

      const item = {
        ...data.item,
        currentContent: {
          content: data.item.currentContent?.[0]?.content,
          submodels: data.item.currentContent?.[0]?.submodels,
          qualityMetrics: data.item.currentContent?.[0]?.qualityMetrics,
        },
        savedContent: {
          content: data.item.savedContent?.[0]?.content,
        },
        originalContent: {
          submodels: data.item.originalContent?.[0]?.submodels,
        },
      }
      return item
    } catch (error) {
      ErrorHandler(error)

      addSnack({
        message: `Error while fetching an item ${error.message}`,
        severity: 'error',
      })

      throw error
    }
  },
)
