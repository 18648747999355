import _ from 'lodash'

import { getApi } from '@core/api'

import type { Instance, Items } from './content-version-types'

const contentVersionMapping = {
  content: 'content',
  qualityMetrics: 'quality_metrics',
  comments: 'comments',
  submodels: 'submodels',
  externalMetadata: 'external_metadata',
  inputs: 'inputs',
  itemBias: 'item_bias',
  itemKeyCheck: 'item_key_check',
}

const ignoreFields = ['__typename', 'type', 'id']

export const updateItems = (rootId: string, items: Items, itemChanges: Instance['itemChanges']) => {
  const variables: any = {
    rootId,
    items: [],
  }

  for (const [itemKey, fields] of Object.entries(itemChanges)) {
    const item = items[itemKey]

    const update = { item_id: item.id }
    const changes = _.pick(item.currentContent, Object.keys({ ...fields, qualityMetrics: true }))

    for (const [key, rawValue] of Object.entries(changes)) {
      if (ignoreFields.includes(key)) continue

      let value = rawValue

      if (key === 'comments') {
        value = (value as any[]).map((comment) => ({
          name: comment.name,
          value: comment.value,
        }))
      }

      const path = contentVersionMapping[key]

      if (path) {
        update[path] = value
      } else {
        console.warn('updateItems: unknown key', { key, value })
      }
    }

    variables.items.push(update)
  }

  const api = getApi()
  return api.updateContentVersions({
    throwOnError: true,
    path: { item_id: rootId },
    body: {
      // This remaps item_id to itemId
      // TODO: someday just make this whole function use itemId
      items: variables.items.map(
        /* eslint-disable camelcase */
        (item) => {
          const { item_id, ...f } = item as any
          f.itemId = item_id
          return f
        },
        /* eslint-enable camelcase */
      ),
    },
  })
}
