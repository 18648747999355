import React from 'react'
import { create } from 'zustand'

import type { Customer, User } from '@containers/main/main-types'

import TIMEOUTS from './constants/timeouts'

export type State = {
  user: User | null
  customer: Customer | null
  userSessionWarningVisible: boolean
  isIdle: boolean
}

type Actions = {
  reset: () => void
}

const initialState: State = {
  user: null,
  customer: null,
  userSessionWarningVisible: false,
  isIdle: false,
}

export const useMainState = create<State & Actions>((set) => {
  let timer: NodeJS.Timeout

  window.onfocus = () => {
    clearTimeout(timer)
    set({ isIdle: false })
    console.debug(new Date(), 'become active')
  }

  // after 2 minutes of inactivity, the app tab will be considered idle
  window.onblur = () => {
    timer = setTimeout(() => {
      console.debug(new Date(), 'become idle')
      set({ isIdle: true })
    }, TIMEOUTS.BECOME_IDLE)
  }

  return {
    ...initialState,

    reset() {
      clearTimeout(timer)
      set(initialState)
    },
  }
})

const buildUserRole = (user: State['user'], customer: State['customer']) => {
  // This should never happen as the components only render when the user is set
  if (!user || !customer) throw new Error('Missing user/customer on main state')

  const isAdmin = user.role === 'Admin'

  // up to customer_admin
  const defaultRole = isAdmin ? 'customer_admin' : 'AUTHOR'
  // up to internal_admin
  let role = defaultRole

  if (user.internalAdmin) {
    role = 'internal_admin'
  }

  return { user, defaultRole, role, isAdmin, customer }
}

// to be used outside of react components
export const getUserRole = () => {
  const { user, customer } = useMainState.getState()

  return buildUserRole(user, customer)
}

// to be used inside react components
export const useUserRole = () => {
  const [user, customer] = useMainState((state) => [state.user, state.customer])

  return React.useMemo(() => buildUserRole(user, customer), [user, customer])
}

export const useIsProBuilderIntegrationEnabled = () => {
  return useMainState((state) => {
    const flag = state.customer?.features?.probuilder_integration || false
    const isAdmin = state.user?.role === 'Admin'

    return flag && isAdmin
  })
}
