import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  type StyleProps,
} from '@mui/material'

import { useAuth0 } from '@auth0/auth0-react'
import { useExportJobs } from '@containers/export-items/export-items-state'
import AdminSettingsDialog from '@containers/main/admin/admin-settings'
import { useAdminSettings } from '@containers/main/admin/admin-settings-state'
import { adminViewOptions } from '@containers/main/admin/admin-views'
import sentry from '@core/api/sentry'
import env from '@core/env'
import { useIsProBuilderIntegrationEnabled, useMainState } from '@core/main-state'

import { DebugItem } from './debug-item'

const styles = {
  internalIcon: {
    mr: 1,
    color: 'text.grayishBlue',
  },
} satisfies StyleProps

const idlePrefix = ['local', 'dev', 'qa'].includes(env.ENV) ? '😴 ' : ''
const envPrefix = ['local', 'dev', 'qa', 'staging'].includes(env.ENV)
  ? `[${env.ENV.toUpperCase()}] `
  : ''

export default function UserProfile() {
  const { logout } = useAuth0()

  const [anchorEl, setAnchorEl] = useState(null)

  const [isIdle, user, customer] = useMainState((state) => [
    state.isIdle,
    state.user,
    state.customer,
  ])

  const isExportEnabled = useIsProBuilderIntegrationEnabled()

  const updateExportDialog = useExportJobs((state) => state.updateDialog)

  const adminSettings = useAdminSettings()

  const handleExpandClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleExpandClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    sentry.setUser(null)
    handleExpandClose()

    let returnTo = window.location.origin

    if (customer?.ssoConnectionName) {
      returnTo = `${window.location.origin}/login/sso?connectionName=${customer.ssoConnectionName}`
    }

    logout({ logoutParams: { returnTo } })
  }

  const { internalAdmin = false } = user || {}

  useEffect(() => {
    if (internalAdmin && customer?.name) {
      document.title = `${isIdle ? idlePrefix : ''}${envPrefix}${customer.name} - Finetune Generate`
    }
  }, [internalAdmin, customer?.name, isIdle])

  const options = internalAdmin ? adminViewOptions : []

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={(theme) => ({
        '& .notifications-icon': {
          fill: theme.palette.primary.main,
        },
      })}
    >
      {user && (
        <Avatar
          alt={user.firstName || user.name}
          src={user.picture}
          data-testid="user-profile-image"
          sx={{
            width: 24,
            height: 24,
            mr: 1,
            bgcolor: '#73737F',
          }}
        />
      )}
      <IconButton
        color="primary"
        aria-label="Profile Menu"
        aria-haspopup="true"
        onClick={handleExpandClick}
        data-testid="user-profile-expand"
        size="large"
      >
        <ExpandMoreIcon />
      </IconButton>
      <Menu
        id="user-profile-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleExpandClose}
        data-testid="user-profile-menu"
        variant="selectedMenu"
      >
        {user && (
          <ListItem>
            <ListItemText
              primary={user.email}
              secondary={user.internalAdmin ? customer?.name : ''}
            />
          </ListItem>
        )}

        {isExportEnabled && (
          <MenuItem
            onClick={() => {
              updateExportDialog({ open: true, tab: 0, selectedJobId: null })
              handleExpandClose()
            }}
          >
            <ListItemText primary="My Exports" />
          </MenuItem>
        )}

        {internalAdmin && <Divider sx={{ color: 'text.grayishBlue' }}>Admin Settings</Divider>}

        {internalAdmin && <DebugItem />}

        {options.map(({ label, key, icon }) => (
          <MenuItem
            key={key}
            onClick={() => {
              adminSettings.setState({ open: true, view: key })
              handleExpandClose()
            }}
          >
            <Box component={icon} sx={styles.internalIcon} />
            <ListItemText primary={label} />
          </MenuItem>
        ))}

        {internalAdmin && <Divider sx={{ my: 2 }} />}

        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>

      {internalAdmin && <AdminSettingsDialog adminSettings={adminSettings} />}
    </Box>
  )
}
