import { gql, TypedDocumentNode } from '@apollo/client'

import type { RawItem } from './author-types'

const ITEM_CONTENT_VERSION = /* GraphQL */ `
  id
  type
  comments
  content
  qualityMetrics: quality_metrics
  externalMetadata: external_metadata
  itemBias: item_bias
  inputs
  submodels
  itemKeyCheck: item_key_check
`

export const GET_ITEM: TypedDocumentNode<{ item: RawItem }, { itemId: string; userId: string }> =
  gql`
  query item($itemId: uuid!, $userId: String!) {
    item: item_by_pk(id: $itemId) {
      id
      isArchived: is_archived
      contentType: content_type
      status
      updatedAt: updated_at
      identifier
      aiResponses: ai_responses(
        limit: 1,
        where: {status: {_eq: FAILED}}, order_by: {modified_at: desc}
      ) {
        error
      }
      aiModel: ai_model {
        id
        public_mapper
        flavors
        comments
        name
        qualityMetrics: quality_metrics
        type

        itemRationaleReferences: resource_settings(path: "$.item_rationale")
        itemRationaleInputs: resource_settings(path: "$.resource_inputs")

        externalMetadataSettings: external_metadata_settings
        internalName: internal_model_name
        optionPrefix: public_mapper(path: "$.option_prefix")
        itemContentSettings: item_content_settings
        generationSettings: generation_settings
        isRddPipeline: is_rdd_pipeline
        permissions(where: { user_id: { _eq: $userId } }) {
          trustVote: trust_vote
          possibleKeys: possible_keys
          rationales
          pretest_bias
          pretest_key
          pretest_style
          pretest_content
          generateMath: generate_math
        }
      }
      job {
        id
        itemsRequired: items_required
        ownerId: owner_id
      }
      project {
        id
        name
        ownerId: owner_id
        access(where: { user_id: { _eq: $userId } }) {
          download
          read
          write
        }
      }
      contentFilter: content_filter

      currentContentVersion: content_version(args:{content_type: "current"}) {
        ${ITEM_CONTENT_VERSION}
      }

      savedContentVersion: content_version(args:{content_type: "saved"}) {
        ${ITEM_CONTENT_VERSION}
      }

      originalContentVersion: content_version(args:{content_type: "original"}) {
        ${ITEM_CONTENT_VERSION}
      }

      votes(where: { user_id: { _eq: $userId } }) {
        vote
      }
      trustedVote: trusted_vote {
        vote
      }
    }
  }
`

export const GET_ITEM_CONTENT_VERSION: TypedDocumentNode<
  { item: Pick<RawItem, 'id' | 'status' | 'updatedAt' | 'currentContentVersion' | 'aiResponses'> },
  { itemId: string }
> = gql`
  query itemContentVersion($itemId: uuid!) {
    item: item_by_pk(id: $itemId) {
      id
      updatedAt: updated_at
      status
      aiResponses: ai_responses(
        limit: 1,
        where: {status: {_eq: FAILED}}, order_by: {modified_at: desc}
      ) {
        error
      }
      currentContentVersion: content_version(args:{content_type: "current"}) {
        ${ITEM_CONTENT_VERSION}
      }
    }
  }
`

export type GenerateItemParams = {
  modelId?: string
  itemsRequired?: number
  itemId?: string
  temperaturePercentage?: number
  flavors?: string[]
  customPassage?: string
  title?: string
  autogenerateBlocks?: boolean
  empty?: boolean
  meta?: Record<string, any>
  inputs?: Record<string, any>
  clearStem?: boolean
  clearOptions?: boolean
  resourceId?: string
}

const aiFields = `
  ai_settings
  responses
  error
  error_message
  parse_templates
  prompt
  status
  created_at
  modified_at
  id
  item_id
  version_id
`

export const DEBUG_ITEM: TypedDocumentNode<
  {
    item: {
      id: string
      ai_response: any[]
      children: {
        id: string
        ai_response: any[]
      }
      aiModel: {
        id: string
        name: string
        internalName: string
        type: string
      }
    }
  },
  { itemId: string }
> = gql`
  query debugItem($itemId: uuid!) {
    item: item_by_pk(id: $itemId) {
      id
      aiModel: ai_model {
        id
        name
        internalName: internal_model_name
        type
      }
      ai_responses {
        ${aiFields}
      }

      children {
        id
        ai_responses {
          ${aiFields}
        }
      }
    }
  }
`
